import { TakebackWithTonic } from "components/TakebackWithTonic";
import { TakebackProvider } from "context/TakebackContext";
import React from "react";

function App() {
  return (
    <TakebackProvider
      clientId="acme-6da31246-c6af-44ca-957a-5b16b0978d36"
      apiEndpoint={process.env.REACT_APP_API || "http://localhost:8788/api"}
    >
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <TakebackWithTonic />
      </div>
    </TakebackProvider>
  );
}

export default App;
