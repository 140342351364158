import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTakebackContext } from "../context/TakebackContext";

export const useTakebackForm = () => {
  const { clientId, apiEndpoint } = useTakebackContext();

  const [formData, setFormData] = useState({
    id: uuidv4(),
    type: "",
    productId: "",
    quantity: 0,
    unit: "",
    manufacturer: "",
    value: "",
    currency: "",
    cost: "",
  });

  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        name === "quantity" || name === "value" || name === "cost"
          ? value === ""
            ? ""
            : Number(value)
          : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: null });

    try {
      const eventPayload = {
        eventId: uuidv4(),
        eventType: "CREATE",
        entityType: "takeback",
        timestamp: new Date().toISOString(),
        source: {
          system: "web-client",
          clientId,
          user: "user-1",
        },
        data: {
          id: formData.id || uuidv4(),
          attributes: {
            clientId,
            type: formData.type,
            productId: formData.productId,
            quantity: Number(formData.quantity) || 0,
            unit: formData.unit,
            manufacturer: formData.manufacturer,
            value: formData.value ? Number(formData.value) : undefined,
            currency: formData.currency || undefined,
            cost: formData.cost ? Number(formData.cost) : undefined,
            statuses: [
              {
                status: "initiated",
                updated_at: new Date().toISOString(),
                details: "Takeback initiated via web form",
              },
            ],
          },
        },
        metadata: {
          correlationId: uuidv4(),
          requestId: uuidv4(),
        },
      };

      const response = await fetch(`${apiEndpoint}/emitters/create-event`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventPayload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.details || response.statusText);
      }

      setStatus({ loading: false, success: true, error: null });
      setFormData({
        id: "",
        type: "",
        productId: "",
        quantity: 0,
        unit: "",
        manufacturer: "",
        value: "",
        currency: "",
        cost: "",
      });
    } catch (error) {
      console.error("Submission error:", error);
      setStatus({
        loading: false,
        success: false,
        error: error.message || "Failed to create takeback event",
      });
    }
  };

  return {
    formData,
    handleChange,
    handleSubmit,
    status,
  };
};
