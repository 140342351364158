import React, { useState } from "react";
import { TakebackForm } from "./TakebackForm";

export const TakebackWithTonic = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="
          bg-gradient-to-r from-blue-500 to-cyan-500
          hover:from-blue-600 hover:to-cyan-600
          text-white
          font-semibold
          py-3
          px-8
          rounded-lg
          shadow-lg
          hover:shadow-xl
          transition-all
          duration-200
          transform hover:scale-105
          text-lg
        "
        data-testid="takeback-button"
      >
        Takeback with Tonic
      </button>

      {/* Modal Backdrop */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center"
          onClick={() => setIsOpen(false)}
        >
          {/* Modal Content */}
          <div
            className="
              bg-white
              rounded-xl
              shadow-2xl
              w-full
              max-w-md
              mx-4
              z-50
              transform
              transition-all
              duration-300
              scale-100
              opacity-100
            "
            onClick={(e) => e.stopPropagation()}
          >
            {/* Modal Header */}
            <div className="flex justify-between items-center p-6 border-b">
              <h2 className="text-xl font-semibold text-gray-800">
                Create New Takeback
              </h2>
              <button
                onClick={() => setIsOpen(false)}
                className="
                  text-gray-400
                  hover:text-gray-600
                  transition-colors
                  duration-200
                  p-1
                  rounded-full
                  hover:bg-gray-100
                "
                aria-label="close"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>

            {/* Modal Body */}
            <div className="p-6">
              <TakebackForm />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
