import { createContext, useContext } from "react";

export const TakebackContext = createContext(null);

export const useTakebackContext = () => {
  const context = useContext(TakebackContext);
  if (!context) {
    throw new Error("Takeback hooks must be used within a TakebackProvider");
  }
  return context;
};

export const TakebackProvider = ({
  children,
  clientId,
  apiEndpoint = "http://localhost:8788/api",
}) => {
  if (!clientId) {
    throw new Error("TakebackProvider requires a clientId prop");
  }

  const value = {
    clientId,
    apiEndpoint,
  };

  return (
    <TakebackContext.Provider value={value}>
      {children}
    </TakebackContext.Provider>
  );
};
